// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-cloud-66-first-impressions-tsx": () => import("./../src/pages/blog/cloud-66-first-impressions.tsx" /* webpackChunkName: "component---src-pages-blog-cloud-66-first-impressions-tsx" */),
  "component---src-pages-blog-full-vps-server-set-up-guide-tsx": () => import("./../src/pages/blog/full-vps-server-set-up-guide.tsx" /* webpackChunkName: "component---src-pages-blog-full-vps-server-set-up-guide-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-jsonb-postgresql-rails-tsx": () => import("./../src/pages/blog/jsonb-postgresql-rails.tsx" /* webpackChunkName: "component---src-pages-blog-jsonb-postgresql-rails-tsx" */),
  "component---src-pages-blog-rails-starter-config-database-postgresql-tsx": () => import("./../src/pages/blog/rails-starter-config-database-postgresql.tsx" /* webpackChunkName: "component---src-pages-blog-rails-starter-config-database-postgresql-tsx" */),
  "component---src-pages-blog-rails-strong-parameters-shortcut-for-scoping-current-user-tsx": () => import("./../src/pages/blog/rails-strong-parameters-shortcut-for-scoping-current-user.tsx" /* webpackChunkName: "component---src-pages-blog-rails-strong-parameters-shortcut-for-scoping-current-user-tsx" */),
  "component---src-pages-blog-rendering-partial-outside-view-or-controller-rails-6-tsx": () => import("./../src/pages/blog/rendering-partial-outside-view-or-controller-rails-6.tsx" /* webpackChunkName: "component---src-pages-blog-rendering-partial-outside-view-or-controller-rails-6-tsx" */),
  "component---src-pages-blog-simple-case-for-search-engine-ads-tsx": () => import("./../src/pages/blog/simple-case-for-search-engine-ads.tsx" /* webpackChunkName: "component---src-pages-blog-simple-case-for-search-engine-ads-tsx" */),
  "component---src-pages-blog-website-images-seo-cro-tsx": () => import("./../src/pages/blog/website-images-seo-cro.tsx" /* webpackChunkName: "component---src-pages-blog-website-images-seo-cro-tsx" */),
  "component---src-pages-blog-what-is-copywriting-tsx": () => import("./../src/pages/blog/what-is-copywriting.tsx" /* webpackChunkName: "component---src-pages-blog-what-is-copywriting-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

